<template>
  <b-overlay
    :show="showOverlay"
  >
    <section id="dashboard-analytics">
      <b-row>
        <b-col cols="12">
          <label>Seleccione la fecha del reporte a generar</label>
        </b-col>
        <b-col cols="6">
          <b-row>
            <b-col cols="12">
              <b-row>
                <b-col cols="6">
                  <label>Mes: </label>
                  <v-select
                    id="vue-select"
                    v-model="mes"
                    :options="meses"
                    label="nombre"
                    @input="getAllData"
                  />
                </b-col>
                <b-col cols="6">
                  <label>Año: </label>
                  <v-select
                    id="vue-select"
                    v-model="año"
                    :options="años"
                    label="nombre"
                    @input="getAllData"
                  />
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12">
            &nbsp;&nbsp;
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col
          :cols="isMobile()? 12: 4"
        >
          <div id="datosVericacion">
            <b-card
              body-class="pb-50 center"
            >
              <h4 style="text-align: center;">
                Promedio en Asignación
              </h4>
              <h1
                style="text-align: center;"
                :class="`font-weight-bolder mb-1 text-${getStatusClass(datosTiempos.verificacion)}`"
              >
                {{ datosTiempos.promedioAsignacion }}
              </h1>
            </b-card>
          </div>
        </b-col>
        <b-col
          :cols="isMobile()? 12: 4"
        >
          <div id="datosVericacion">
            <b-card
              body-class="pb-50 center"
            >
              <h4 style="text-align: center;">
                Horas Promedio en Atención
              </h4>
              <h1
                style="text-align: center;"
                :class="`font-weight-bolder mb-1 text-${getStatusClass(datosTiempos.verificacion)}`"
              >
                {{ datosTiempos.verificacion }}
              </h1>
            </b-card>
          </div>
        </b-col>
        <b-col
          :cols="isMobile()? 12: 4"
        >
          <div id="datosAtencion">
            <b-card
              body-class="pb-50 center"
            >
              <h4 style="text-align: center;">
                Horas Promedio en Reparación
              </h4>
              <h1
                style="text-align: center;"
                :class="`font-weight-bolder mb-1 text-${getStatusClass(datosTiempos.atencion)}`"
              >
                {{ datosTiempos.atencion }}
              </h1>
            </b-card>
          </div>
        </b-col>
        <b-col
          :cols="isMobile()? 12: 6"
        >
          <div id="grafica_tickets">
            <donut-chart
              :key="graficaTicketsOrigen.counter"
              :subtitle="'Información del mes en curso'"
              :title="'Tickets por Origen'"
              :data="graficaTicketsOrigen.data"
              :chart-options="graficaTicketsOrigen.options"
            />
          </div>
        </b-col>
        <b-col
          :cols="isMobile()? 12: 6"
        >
          <div id="grafica_tickets">
            <donut-chart
              :key="graficaDetalleTickets.counter"
              :subtitle="'Información del mes en curso'"
              :title="'Detalle de Tickets'"
              :data="graficaDetalleTickets.data"
              :chart-options="graficaDetalleTickets.options"
            />
          </div>
        </b-col>
        <b-col
          :cols="isMobile()? 12: 6"
        >
          <div id="grafica_inspecciones">
            <donut-chart
              :key="graficaDetalleInspecciones.counter"
              :subtitle="'Información del mes en curso'"
              :title="'Detalle de Inspecciones'"
              :data="graficaDetalleInspecciones.data"
              :chart-options="graficaDetalleInspecciones.options"
            />
          </div>
        </b-col>
        <b-col
          :cols="isMobile()? 12: 6"
        >
          <div id="grafica_ampliaciones">
            <donut-chart
              :key="graficaDetalleAmpliaciones.counter"
              :subtitle="'Información del mes en curso'"
              :title="'Detalle de Ampliaciones'"
              :data="graficaDetalleAmpliaciones.data"
              :chart-options="graficaDetalleAmpliaciones.options"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          :cols="isMobile()? 12: 6"
        >
          <div id="grafica_fallas_tipo">
            <bar-chart
              :data="graficaFallasReportadas"
              :title="'Tipos de Falla Reportadas'"
              :subtitle="'Información del mes en curso'"
            />
          </div>
        </b-col>
        <b-col
          :cols="isMobile()? 12: 6"
        >
          <div id="grafica_informe_mensual">
            <mantenimiento-preventivo-chart
              :mes="mes"
              :año="año"
            />
          </div>
        </b-col>
        <b-col cols="12">
          <div id="grafica_fallas_localidad">
            <bar-chart
              :data="graficaFallasLocalidadPoblacion"
              :title="'Fallas por Localidad / Población'"
              :subtitle="'Información del mes en curso'"
            />
          </div>
        </b-col>
      </b-row>
    </section>
  </b-overlay>
</template>

<script>

import {
  BRow, BCol, BOverlay, BCard,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  countTickets, decimals, findTickets, getHoras,
} from '@/utils/tickets'
import {
  barsChartOptions,
  chartDonutOptions,
} from '@/utils/charts'
import DonutChart from '@/components/charts/DonutChart.vue'
import BarChart from '@/components/charts/BarChart.vue'
import {
  counInspecciones, countAmpliaciones, findAmpliaciones, findInspecciones,
} from '@/utils/inspecciones'
import { setLocalidadesTicketsPostes } from '@/utils/postes'
import MantenimientoPreventivoChart from '@/components/charts/mantenimiento-preventivo-chart.vue'
import { getListadoMeses } from '@/utils/fechas'

export default {
  components: {
    MantenimientoPreventivoChart,
    BarChart,
    BRow,
    BCol,
    vSelect,
    BOverlay,
    DonutChart,
    BCard,
  },
  data() {
    return {
      usuario: JSON.parse(localStorage.getItem('userData')),
      mes: null,
      año: null,
      tickets: [],
      años: [],
      meses: [],
      showOverlay: false,
      datosTiempos: {
        verificacion: 0,
        atencion: 0,
        asignacion: 0,
      },
      graficaDetalleTickets: {
        data: [],
        options: {},
        counter: 0,
      },
      graficaTicketsOrigen: {
        data: [],
        options: {},
        counter: 0,
      },
      graficaDetalleInspecciones: {
        data: [],
        options: {},
        counter: 0,
      },
      graficaDetalleAmpliaciones: {
        data: [],
        options: {},
        counter: 0,
      },
      graficaFallasLocalidadPoblacion: {
        series: [{
          data: [],
        }],
        chartOptions: {
          chart: {
            height: 350,
            type: 'bar',
          },
        },
      },
      graficaFallasReportadas: {
        series: [{
          data: [],
        }],
        chartOptions: {
          chart: {
            height: 350,
            type: 'bar',
          },
        },
      },
      graficaInspeccionesUsuario: {
        series: [{
          data: [],
        }],
        chartOptions: {
          chart: {
            height: 350,
            type: 'bar',
          },
        },
      },
      mesGrafica: null,
      añoGrafica: null,
    }
  },
  async created() {
    this.años = getListadoMeses(2)
    this.meses = getListadoMeses(1)
    this.showOverlay = true
    // eslint-disable-next-line prefer-destructuring
    this.año = this.años.filter(a => Number(a.id) === (new Date().getFullYear()))[0]
    // eslint-disable-next-line prefer-destructuring
    this.mes = this.meses.filter(a => Number(a.id) === (new Date().getMonth() + 1))[0]

    await this.getAllData()
  },
  methods: {
    getAllData() {
      this.showOverlay = true
      this.obtenerTotalesDonas('T')
      this.obtenerTotalesDonas('I')
      this.obtenerTotalesDonas('A')
      this.obtenerDonasPorOrigen()
      this.obtenerBarras('T')
      this.obtenerBarras('I')
    },
    isMobile() {
      return window.screen.width <= 760
    },
    getStatusClass(horas) {
      if (horas < 6) return 'success'
      if (horas >= 6 && horas < 10) return 'secondary'
      if (horas >= 10 && horas < 12) return 'warning'
      return 'danger'
    },
    getTiempos() {
      const tickets = this.tickets.filter(t => t.estado !== 'ANULADO')
      this.datosTiempos.verificacion = 0
      this.datosTiempos.atencion = 0
      this.datosTiempos.asignacion = 0
      tickets.forEach(ticket => {
        this.datosTiempos.verificacion += Number(getHoras(ticket, 'V'))
        this.datosTiempos.atencion += Number(getHoras(ticket, 'A'))
        this.datosTiempos.asignacion += Number(getHoras(ticket, 'S'))
      })
      this.datosTiempos.verificacion /= tickets.length
      this.datosTiempos.atencion /= tickets.length
      this.datosTiempos.asignacion /= tickets.length

      this.datosTiempos.verificacion = decimals(this.datosTiempos.verificacion)
      this.datosTiempos.promedioAsignacion = this.datosTiempos.asignacion
      this.datosTiempos.atencion = decimals(this.datosTiempos.atencion)
      this.datosTiempos.promedioAsignacion = decimals(this.datosTiempos.promedioAsignacion)
    },
    // eslint-disable-next-line consistent-return
    async getData(estado, tipo, estadoNeq = '') {
      const fechaFin = new Date(`${this.año.id}-${this.mes.id}-${this.mes.dia}`)
      const fechaInicio = new Date(`${this.año.id}-${this.mes.id}-01`)
      const where = {
        and: [
          { companyId: this.usuario.idCompany },
          { fechaCreacion: { gte: fechaInicio.toISOString() } },
          { fechaCreacion: { lte: fechaFin.toISOString() } },
        ],
      }

      const filter = {
        where,
        order: ['fechaCreacion DESC'],
      }

      if (estado !== '') {
        where.and.push({
          estado,
        })
      }

      if (estadoNeq !== '') {
        where.and.push({
          estado: { neq: estadoNeq },
        })
      }

      if (tipo === 'T') {
        if (estado !== '') {
          const resultado = await countTickets(where)
          return resultado.count
        }
        this.tickets = await findTickets(filter, true)
        this.getTiempos()
        return setLocalidadesTicketsPostes(this.tickets, this.usuario.company.municipio)
      }
      if (tipo === 'I') {
        if (estado !== '') {
          const resultado = await counInspecciones(where)
          return resultado.count
        }

        filter.where = {
          and: [
            { companyId: this.usuario.idCompany },
            { fechaCreacion: { gte: fechaInicio.toISOString() } },
            { fechaCreacion: { lte: fechaFin.toISOString() } },
            { estado: 'FINALIZADA' },
          ],
        }

        return findInspecciones(filter)
      }
      if (tipo === 'A') {
        if (estado !== '') {
          const resultado = await countAmpliaciones(where)
          return resultado.count
        }

        return findAmpliaciones(filter)
      }
    },
    obtenerDonasPorOrigen() {
      this.getData('', 'T', 'ANULADO').then(resultado => {
        const titulos = ['VECINO', 'MUNICIPALIDAD', 'APVN', 'OTROS']
        const values = [resultado.filter(r => r.fuente.nombre === 'VECINO').length,
          resultado.filter(r => r.fuente.nombre === 'MUNICIPALIDAD').length,
          resultado.filter(r => r.fuente.nombre === 'APVN').length,
          resultado.filter(r => r.fuente.nombre === 'OTROS').length]
        const total = values.reduce((a, b) => a + b, 0)
        const options = chartDonutOptions(titulos, total)

        this.graficaTicketsOrigen.data = values
        this.graficaTicketsOrigen.options = options
        this.graficaTicketsOrigen.counter += 1
      }).catch(err => {
        console.error('Error obteniendo info de grafico de tickets de origen', err)
      })
    },
    obtenerTotalesDonas(tipo) {
      let titulos = []

      if (tipo === 'T') titulos = ['Confirmación', 'Pendientes', 'Asignados', 'En Curso', 'Finalizados', 'Anulados']
      else if (tipo === 'I') titulos = ['Pendientes', 'Asignadas', 'En Curso', 'Finalizadas']
      else if (tipo === 'A') titulos = ['En Autorización', 'Autorizadas / Pendientes', 'Asignadas', 'En Curso', 'Finalizadas', 'Facturadas', 'Anuladas']

      let categoriasDatos = []

      if (tipo === 'T') {
        categoriasDatos = [
          'CONFIRMACION',
          'PENDIENTE',
          'ASIGNADO',
          'EN CURSO',
          'FINALIZADA',
          'ANULADO',
        ]
      } else if (tipo === 'I') {
        categoriasDatos = [
          'PENDIENTE',
          'ASIGNADO',
          'EN CURSO',
          'FINALIZADA',
        ]
      } else if (tipo === 'A') {
        categoriasDatos = [
          'AUTORIZACION',
          'AUTORIZADO',
          'ASIGNADA',
          'EN CURSO',
          'FINALIZADA',
          'FACTURADO',
          'ANULADA',
        ]
      }

      const gruposPromesas = categoriasDatos.map(categoria => this.getData(categoria, tipo))

      Promise.all(gruposPromesas).then(gruposValores => {
        const values = gruposValores.flat()

        const total = values.reduce((a, b) => a + b, 0)
        const options = chartDonutOptions(titulos, total)

        if (tipo === 'T') {
          this.graficaDetalleTickets.data = values
          this.graficaDetalleTickets.options = options
          this.graficaDetalleTickets.counter += 1
        } else if (tipo === 'I') {
          this.graficaDetalleInspecciones.data = values
          this.graficaDetalleInspecciones.options = options
          this.graficaDetalleInspecciones.counter += 1
        } else if (tipo === 'A') {
          this.graficaDetalleAmpliaciones.data = values
          this.graficaDetalleAmpliaciones.options = options
          this.graficaDetalleAmpliaciones.counter += 1
        }
      })
    },
    obtenerBarras(tipo) {
      this.getData('', tipo).then(data => {
        if (tipo === 'T') {
          const conteoFallasTipo = data.reduce((acum, item) => (!acum[item.falla.nombre]
            ? { ...acum, [item.falla.nombre]: 1 }
            : { ...acum, [item.falla.nombre]: acum[item.falla.nombre] + 1 }), {})

          const conteoFallasLocalidad = data.reduce((acum, item) => (!acum[item.punto.obj_localidad.nombre]
            ? { ...acum, [item.punto.obj_localidad.nombre]: 1 }
            : { ...acum, [item.punto.obj_localidad.nombre]: acum[item.punto.obj_localidad.nombre] + 1 }), {})
          const dataFallasTipo = this.obtenerArrayParseado(conteoFallasTipo)

          /* const MyComponentConstructor = Vue.extend(BarChart)
          const vm = new MyComponentConstructor()
          vm.title = titulo
          vm.data = barsChartOptions(dataFallasTipo.claves, dataFallasTipo.valores)
          vm.subtitle = 'Información del mes en curso'
          vm.$mount(`#${div}`) */
          this.graficaFallasReportadas = barsChartOptions(dataFallasTipo.claves, dataFallasTipo.valores)

          const dataFallasLocalidad = this.obtenerArrayParseado(conteoFallasLocalidad)
          /* const vm2 = new MyComponentConstructor()
          vm2.title = 'Fallas por Localidad / Población'
          vm2.data = barsChartOptions(dataFallasLocalidad.claves, dataFallasLocalidad.valores)
          vm2.subtitle = 'Información del mes en curso'
          this.optionsGrafica = vm2.data
          vm2.$mount('#grafica_fallas_localidad') */
          this.graficaFallasLocalidadPoblacion = barsChartOptions(dataFallasLocalidad.claves, dataFallasLocalidad.valores)
        } else if (tipo === 'I') {
          const conteoInspeccionesUsuario = data.reduce((acum, item) => (!acum[item.usuario.nombre]
            ? { ...acum, [item.usuario.nombre]: 1 }
            : { ...acum, [item.usuario.nombre]: acum[item.usuario.nombre] + 1 }), {})

          const dataUsuarios = this.obtenerArrayParseado(conteoInspeccionesUsuario)

          /* const MyComponentConstructor = Vue.extend(BarChart)
          const vm = new MyComponentConstructor()
          vm.title = titulo
          vm.data = barsChartOptions(dataUsuarios.claves, dataUsuarios.valores)
          vm.subtitle = 'Información del mes en curso'
          vm.$mount(`#${div}`) */
          this.graficaInspeccionesUsuario = barsChartOptions(dataUsuarios.claves, dataUsuarios.valores)
          this.showOverlay = false
        }
      })
    },
    obtenerArrayParseado(data) {
      const resultado = {
        claves: [],
        valores: [],
      }
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const prop in data) {
        resultado.claves.push(prop)
        resultado.valores.push(data[prop])
      }
      return resultado
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
